import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";

import Select from "react-select";
// import ReCAPTCHA from "react-google-recaptcha";

import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { actionCreaters } from "../Redux";
import { bindActionCreators } from "redux";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    sales_name_f: "",
    sales_name_l: "",
    store_location: "",
    emailid: "",
    name: "",
    lname: "",
    address: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    i_agree: "",
    // captcha: "",
  });

  const [errors, setErrors] = useState({});
  // const [captcha, setCaptcha] = useState(false);
  const [storelocation, setStoreLocation] = useState([]);

  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { rsaAccessToken, rsa_id } = state;

  useEffect(() => {
    if ((rsaAccessToken, rsa_id)) {
      setShow(true);
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const decoded = jwtDecode(rsaAccessToken);
      if (currentTime > decoded.exp) {
        toast.error("RSA request time out!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        userActions.logOut("rsaLogOut");
        navigate("/register-login", { replace: true });
      }
    } else {
      navigate("/register-login", { replace: true });
    }
  }, [rsaAccessToken, rsa_id]);

  useEffect(() => {
    async function getLocations() {
      let errors = {};

      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.data;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.id,
            label: value.store_location,
          });
        });
        await setStoreLocation([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    getLocations();
  }, []);

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    if (name === "i_agree") {
      value = e.target.checked;
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();

    let data = {
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      store_location: fields?.store_location,
      email: fields?.emailid,
      fname: fields?.name,
      lname: fields?.lname,

      phone: fields?.phone,
      i_agree: fields?.i_agree,
      userId: rsa_id,
      // captcha: captcha,
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                sales_name_f: fields.sales_name_f,
                sales_name_l: fields.sales_name_l,
                store_location: fields.store_location,
                emailid: fields.emailid,
                name: fields.name,
                lname: fields.lname,
                address: fields.address,
                p_address: fields.p_address,
                city: fields.city,
                state: fields.state,
                zipcode: fields.zipcode,
                phone: fields.phone,
                // captcha: captcha,
              })
            );
            localStorage.removeItem("return_page");
            localStorage.setItem("isRegister", true);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);

          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              logOut();
            }, 1500);
          } else if (err?.response?.status === 422) {
            let errorData = {};
            errorData["sales_name_f"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name_f &&
              err?.response?.data?.message.sales_name_f[0];
            errorData["sales_name_l"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name_l &&
              err?.response?.data?.message.sales_name_l[0];
            errorData["store_location"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.store_location &&
              err?.response?.data?.message.store_location[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];

            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["i_agree"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.i_agree &&
              err?.response?.data?.message.i_agree[0];
            errorData["checkederrorcaptcha"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.captcha &&
              err?.response?.data?.message.captcha[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            setTimeout(() => {
              ref?.current?.focus();
            }, 200);
          }
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["sales_name_f"] = err?.response?.data?.message.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message.sales_name_l[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["emailid"] = err?.response?.data?.message.phone[0];
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];

      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
  };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }

  const logOut = async () => {
    await userActions.logOut("rsaLogOut");
    navigate("/register-login", { replace: true });
  };
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {show ? (
        <div className="container-fluid">
          <div className="position-relative">
            <button onClick={logOut} className="logout-btn-reg">
              Logout
            </button>
          </div>
          <div id="main-registration-container">
            <div className="white-box form" id="font-style">
              <h1>Register your guest for their 30oz Stanley Tumbler!</h1>
              <hr />
              <form
                name="userRegistrationForm"
                className="form-outer"
                onSubmit={submituserRegistrationForm}
                // onClick={this.popup}
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="formsub">
                      Employee First Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Employee First Name"
                      style={
                        errors.sales_name_f ? { border: "1px solid red" } : null
                      }
                      name="sales_name_f"
                      // tabIndex="1"
                      id="sales_name_f"
                      className="searchBox_deals vc-validate"
                      ref={errors.sales_name_f ? ref : null}
                      value={fields.sales_name_f || ""}
                      autoFocus=""
                      onChange={handleChange}
                    />
                    <div className="errorMsg">{errors.sales_name_f}</div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      Employee Last Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="sales_name_l"
                      style={
                        errors.sales_name_l && !errors.sales_name_f
                          ? { border: "1px solid red" }
                          : null
                      }
                      placeholder="Employee Last Name"
                      // tabIndex="1"
                      id="sales_name_l"
                      className="searchBox_deals vc-validate"
                      value={fields.sales_name_l || ""}
                      ref={
                        errors.sales_name_l && !errors.sales_name_f ? ref : null
                      }
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.sales_name_l && !errors.sales_name_f
                        ? errors.sales_name_l
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Store Identifier <span className="red_point">*</span>
                    </label>

                    <Select
                      classNamePrefix="react-select"
                      className={`select_1 dropdown ${
                        errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? "error-select-search"
                          : ""
                      }`}
                      placeholder="Select Store Identifier"
                      options={storelocation}
                      isSearchable={true}
                      isClearable={true}
                      name="store_location"
                      ref={
                        errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? ref
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["store_location"]: selectedOption.value,
                            };
                          });
                        } else {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["store_location"]: "",
                            };
                          });
                        }
                      }}
                    />

                    <div className="errorMsg">
                      {errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.store_location
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Email <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailid"
                      // tabIndex="10"
                      ref={
                        errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? ref
                          : null
                      }
                      style={
                        errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? { border: "1px solid red" }
                          : null
                      }
                      id="emailid"
                      placeholder="E-mail"
                      value={fields.emailid || ""}
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.emailid
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer First Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      style={
                        errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? { border: "1px solid red" }
                          : null
                      }
                      name="name"
                      // tabIndex="1"
                      id="fname"
                      className="searchBox_deals vc-validate"
                      ref={
                        errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? ref
                          : null
                      }
                      value={fields.name || ""}
                      autoFocus=""
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.name
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Last Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="lname"
                      style={
                        errors.lname &&
                        !errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? { border: "1px solid red" }
                          : null
                      }
                      placeholder="Last Name"
                      // tabIndex="1"
                      id="lname"
                      className="searchBox_deals vc-validate"
                      value={fields.lname || ""}
                      ref={
                        errors.lname &&
                        !errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? ref
                          : null
                      }
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.lname &&
                      !errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.lname
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Phone# <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      id="phone"
                      style={
                        errors.phone &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? { border: "1px solid red" }
                          : null
                      }
                      name="phone"
                      placeholder="Phone"
                      value={fields.phone || ""}
                      ref={
                        errors.phone &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.emailid &&
                        !errors.store_location &&
                        !errors.sales_name_l &&
                        !errors.sales_name_f
                          ? ref
                          : null
                      }
                      onChange={handleChange}
                      onKeyUp={onlyNumbers}
                      onPaste={pasted}
                      maxLength={10}
                    />
                    <div className="errorMsg">
                      {" "}
                      {errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.phone
                        : null}
                    </div>
                  </div>

                  {/* <br />
                  <br />
                  <div className="overflow-hider">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                      onChange={onCaptchaChange}
                    />
                    <div className="errorMsg">
                      {!errors.phone &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? errors.checkederrorcaptcha
                        : null}
                    </div>
                  </div> */}

                  <div className="clearfix"></div>
                </div>

                <div className="alert alert-info">
                  Limit 1 per household, while supplies last. Some restrictions
                  apply, see store for detail.
                </div>

                <b className="fw-medium">
                  <input
                    type="checkbox"
                    id="marketing"
                    value={fields.i_agree}
                    name="i_agree"
                    onChange={handleChange}
                  />
                  This information will be used by Mathis Brothers for marketing
                  purposes, in exchange for a free reward. Please visit Mathis
                  Brothers privacy policy linked below for more information.
                </b>
                <a
                  href="https://www.mathishome.com/information/privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="privacy-link"
                >
                  <i className="bi bi-link-45deg"></i>
                  Privacy Policy
                </a>

                <div className="errorMsg">
                  {errors.i_agree &&
                  !errors.phone &&
                  !errors.zipcode &&
                  !errors.state &&
                  !errors.city &&
                  !errors.address &&
                  !errors.lname &&
                  !errors.name &&
                  !errors.emailid &&
                  !errors.store_location &&
                  !errors.sales_name_l &&
                  !errors.sales_name_f
                    ? errors.i_agree
                    : null}
                </div>
                <br />
                <input
                  type="submit"
                  name="submit"
                  id="submit"
                  value="SUBMIT"
                  className="sub-btn2 button Formfirst"
                />
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="fix-white d-block"></div>
      )}
      <Footer2 />
    </>
  );
}
export default RegisterForm;
